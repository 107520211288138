/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Fuentes */
@import "./assets/fonts/fonts.scss";

/* FIX PAR CHROME POPOVER SELECT */

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.description_acerca_pack {
  p{
    font-size: 1.25rem !important;
  }
}

:root {
  --ion-font-family: "PP NeueBit";
}

.custom-badge {
  border: 1px solid white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  background-color: rgba(255, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-full {
  height: 100% !important;
}

.h-90 {
  height: 90% !important;
}

label {
  font-size: 2rem;
}

.font-iner{
  font-family: "inter";
}

.font-editorial{
  font-family: "Editorial New";
}

.lighter-text {
  color: var(--ion-color-medium-shade);
}

.font-10 {
  font-size: 10px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-25 {
  font-size: 25px;
}

.flex {
  display: flex !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.align-center {
  align-items: center !important;
}

.align-end {
  align-items: flex-end !important;
}

.hover-to-dark {
  --background-hover: rgb(51, 45, 45);
  --background-hover-opacity: 1;
  --color-hover: white;
  --background: var(--ion-color-light);
  --color: black;
  --transition: 800ms;
}

.hover-to-light {
  --background-hover: #241e29;
  --background-hover-opacity: 1;
  --color-hover: #fff;
  --background: transparent;
  --color: white;
  --transition: 800ms;
  --border-radius: 5px;
}

.pointer {
  cursor: pointer !important;
  //font-size: 1.5rem;
}

.nowrap {
  flex-wrap: nowrap;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mb-2r {
  margin-bottom: 2rem !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-4r {
  margin-top: 4rem !important;
}

.mt-10r {
  margin-top: 10rem !important;
}

.bold {
  font-weight: bold;
}

.opacity-100 {
  opacity: 1;
}

.opacity-80 {
  opacity: 0.95;
}

.opacity-95 {
  opacity: 0.5;
}

.text-end {
  text-align: end !important;
}

.text-center {
  text-align: center !important;
}

.ion-items-centered {
  display: flex;
  justify-content: space-evenly;
}

.ion-items-as-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.w-full {
  width: 100% !important;
}

.ion-custom-button {
  --border-color: var(--ion-color-light);
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: initial;
  --padding-top: 1.5rem;
  --padding-bottom: 1.5rem;
  --padding-start: 3rem;
  --padding-end: 3rem;
}

// .native-input[type="date"]::-webkit-calendar-picker-indicator {
// filter: invert(1);
// }

ion-input,
ion-select {
  border: 1px solid #ffffff;
  border-radius: 10px;
  --background: transparent;
  --padding-start: 1rem !important;
  --padding-end: 1rem !important;
  --padding-top: 1rem !important;
  --padding-bottom: 1rem !important;
}

/* Se modifico para dillom */

ion-button {
  letter-spacing: normal;
  text-transform: none;
  font-family: "PP NeueBit";
}

/* Se modifico para dillom */

ion-button[size="large"] {
  --border-radius: 200px !important;
  --padding-start: 3rem !important;
  --padding-end: 3rem !important;
  --padding-top: 0.5rem !important;
  --padding-bottom: 0.5rem !important;  
  height: initial;
}

ion-modal {
  --border-radius: 0.7rem !important;
}

.fan-loading {
  --background: var(--ion-color-dark-tint);
  color: white;
  font-size: 1.25rem;
}

.signing-modal {
  --width: 500px !important;
  --height: 715px !important;
}

.custom-modal {
  --width: 760px !important;
  --height: 390px !important;
}

.custom-show {
  position: absolute;
  bottom: 13px;
  right: 28px;
  z-index: 999;
}

.upload-modal {
  --width: 750px !important;
}

.detailed-modal {
  --width: 875px !important;
}

.normal-modal {
  --width: 50% !important;
  @media (max-width: 768px) {
    --width: 100% !important;
  }
}

.wide-modal {
  --width: 100% !important;
  --height: 100% !important;

  @media (min-width: 768px) {
    --width: 90% !important;
    --height: 90% !important;
  }
}

.split-pane-visible > .split-pane-side {
  max-width: 350px !important;
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning);
  --ion-color-base-rgb: var(--ion-color-warning-rgb);
  --ion-color-contrast: var(--ion-color-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-shade);
  --ion-color-tint: var(--ion-color-warning-tint);
}

ion-datetime {
  border-radius: 16px;
}

.popover-content {
  width: 320px;
}

.custom-popover {
  --width: 350px !important;
}

.mp-modal-size {
  --width: 600px !important;
  --height: 650px !important;
}

.successful-singup-modal-size {
  --width: 480px !important;
  --height: 520px !important;
}

.login-modal-size {
  --width: 480px !important;
  --height: 750px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #595959;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(165, 156, 156);
}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      color: white;
    }
  }
}

// To remove arrows on input[type=number]
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}

ion-popover {
  --background: transparent !important;
}

ion-select-popover {
  ion-list {
    background-color: rgba(56, 58, 62, 0.895) !important;
    --background: rgba(56, 58, 62, 0.895) !important;

    ion-item {
      --color: var(--ion-color-light);
      --background: rgba(56, 58, 62, 0.895) !important;
      --inner-border-width: 0 !important;

      &.ion-focused {
        label {
          --color: grey !important;
        }
      }
    }
  }
}

ion-item {
  --inner-border-width: 0 !important;
}

.popoverInterfaceClass .options {
  --color: #fff;
}

.item-interactive {
  --highlight-background: var(--ion-color-medium-shade);
}

.alert-button.sc-ion-alert-ios:last-child {
  color: black;
}

.fan-title {
  margin-top: 0 !important;
  color: var(--ion-color-primary);
  padding: 0.2rem 0;
  font-weight: bold;
}

h1.fan-title {
  text-align: left;
}

.fan-link {
  color: var(--ion-color-primary);
  padding: 0.2rem 0;
  font-size: 1.25rem;
  font-weight: bold;
  border-bottom: 0.2rem solid white;
}

.upload-dropzone-section {
  width: 100% !important;
  height: 160px !important;

  //background-color: #F3F3F3;
  //background-image: repeating-linear-gradient(0deg, #d2cbcb, #d2cbcb 17px, transparent 17px, transparent 29px, #d2cbcb 29px), repeating-linear-gradient(90deg, #d2cbcb, #d2cbcb 17px, transparent 17px, transparent 29px, #d2cbcb 29px), repeating-linear-gradient(180deg, #d2cbcb, #d2cbcb 17px, transparent 17px, transparent 29px, #d2cbcb 29px), repeating-linear-gradient(270deg, #d2cbcb, #d2cbcb 17px, transparent 17px, transparent 29px, #d2cbcb 29px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;

  .dropzone-title,
  .dropzone-subtitle,
  .dropzone-formats {
    display: flex;
    justify-content: center;
    color: white !important;
    font-size: 1rem !important;
  }
}

/* Estilos para Dillom */

.dillom-box{
  background-color: #88888883;
  padding: 0rem 1rem;
  border-radius: .5rem;
  list-style: none;
  display: flex;
  justify-content: left;
  gap: 4px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  ion-text{
    font-size: 1.25rem;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }

  li {
    padding: 0.25rem;
    align-items: center;
    
    @media (max-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

/* TAMAÑOS DE LETRAS */

/* Dispositivos móviles */
@media (max-width: 767px) {
  html {
    font-size: 12px; /* Aumentar el tamaño base para dispositivos móviles */
  }
  /* Otros estilos específicos para móviles */
}

/* Dispositivos de escritorio */
@media (min-width: 768px) {
  html {
    font-size: 16px; /* Mantener el tamaño base estándar para escritorio */
  }
  /* Otros estilos específicos para escritorio */
}

p {
  font-size: 2rem;
}

ion-label {
  font-size: 2rem;
}

ion-input {
  font-size: 1.25rem;
}

ion-text {
  font-size: 1.25rem;
}

h1 {
  font-size: 4rem;
  font-family: "Editorial New";
  font-weight: 400;
}

h2 {
  font-size: 3rem;
  font-family: "Editorial New";
  font-weight: 300 !important;
}

h3 {
  font-size: 2.2rem;
  font-family: "Editorial New";
}

h4 {
  font-size: 1.25rem;
  font-family: "Editorial New";
}

h5 {
  font-size: 1.25rem;
  font-family: "Editorial New";
}

.login-modal-size {
  ion-backdrop{
    --background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(33,38,41,1) 50%, rgba(0,0,0,1) 100%) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.7) !important;
  }    
}

.font-size-small{
  font-size:.9rem !important;
}

.color-gray{
  color: gray !important;
}

@media (min-width: 768px) {
  .hide-desktop{
    display: none !important;
  }
}

@media (max-width: 768px) {
  .hide-mobile{
    display: none !important;
  }

  .custom-show{
    bottom: 8px;
  }

  .dillom-box{
    padding: 1rem;
  }

  app-header{
    position: fixed;
    width: 100%;
    opacity: 1;
    z-index: 5000;
  }

  .mobile-start-margin{
    margin-top: 30px;
  }
}

.alert-dillom{
  .alert-title{
    font-size: 0.9rem;
  }
  .alert-message{
    font-size: 1.25rem;
  }
  .alert-button{
    font-size: 1.25rem;
  }
}

.qrcode{
  width: 100%;
  text-align: center;
  margin-top: 15px;
  img{
    height: 350px;
  }
}
