@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SF-Pro-Display-Regular.otf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SF Pro Display bold";
  src: url("../fonts/SFPro/SF-Pro-Display-Bold.otf");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SF Pro Display medium";
  src: url("../fonts/SFPro/SF-Pro-Display-Medium.otf");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "inter";
  src: url("./Inter-Regular.ttf");
}

@font-face {
  font-family: "PT Sans Narrow";
  src: url("./PTSansNarrow-Regular.ttf");
}

@font-face {
  font-family: "Bebas Kai";
  src: url("../fonts/BebasKai/BebasKai.ttf");
}

@font-face {
  font-family: "Editorial New Bold";
  src: url("../fonts/Editorial\ New/EditorialNew-Bold.otf");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Editorial New";
  src: url("../fonts/Editorial\ New/EditorialNew-Variable.ttf");
}

@font-face {
  font-family: "PP NeueBit";
  src: url("../fonts/PPNeueBit-Bold.otf");
}


